const timezones = [
   {
      label: "Local Time",
      tzCode: "Local Time",
      name: "Local Time",
      utc: "",
   },
   {
      label: "UTC/GMT (GMT+00:00)",
      tzCode: "UTC",
      name: "(GMT+00:00) UTC/GMT +0 Time Zone",
      utc: "+00:00",
   },
   {
      label: "Pacific/Midway (GMT-11:00)",
      tzCode: "Pacific/Midway",
      name: "(GMT-11:00) Midway",
      utc: "-11:00",
   },
   {
      label: "Pacific/Honolulu (GMT-10:00)",
      tzCode: "Pacific/Honolulu",
      name: "(GMT-10:00) Honolulu, East Honolulu, Pearl City, Hilo, Kailua",
      utc: "-10:00",
   },
   {
      label: "Pacific/Marquesas (GMT-09:30)",
      tzCode: "Pacific/Marquesas",
      name: "(GMT-09:30) Taiohae",
      utc: "-09:30",
   },
   {
      label: "America/Anchorage (GMT-09:00)",
      tzCode: "America/Anchorage",
      name: "(GMT-09:00) Anchorage, Fairbanks, Eagle River, Badger, Knik-Fairview",
      utc: "-09:00",
   },
   {
      label: "America/Los Angeles (GMT-08:00)",
      tzCode: "America/Los_Angeles",
      name: "(GMT-08:00) Los Angeles, San Diego, San Jose, San Francisco, Seattle",
      utc: "-08:00",
   },
   {
      label: "America/Denver (GMT-07:00)",
      tzCode: "America/Denver",
      name: "(GMT-07:00) Denver, El Paso, Albuquerque, Colorado Springs, Aurora",
      utc: "-07:00",
   },
   {
      label: "America/Chicago (GMT-06:00)",
      tzCode: "America/Chicago",
      name: "(GMT-06:00) Chicago, Houston, San Antonio, Dallas, Austin",
      utc: "-06:00",
   },
   {
      label: "America/Mexico City (GMT-06:00)",
      tzCode: "America/Mexico_City",
      name: "(GMT-06:00) Mexico City, Iztapalapa, Ecatepec de Morelos, Guadalajara, Puebla",
      utc: "-06:00",
   },
   {
      label: "America/Detroit (GMT-05:00)",
      tzCode: "America/Detroit",
      name: "(GMT-05:00) Detroit, Grand Rapids, Warren, Sterling Heights, Ann Arbor",
      utc: "-05:00",
   },
   {
      label: "America/Jamaica (GMT-05:00)",
      tzCode: "America/Jamaica",
      name: "(GMT-05:00) Kingston, New Kingston, Spanish Town, Portmore, Montego Bay",
      utc: "-05:00",
   },
   {
      label: "America/New York (GMT-05:00)",
      tzCode: "America/New_York",
      name: "(GMT-05:00) New York City, Brooklyn, Queens, Philadelphia, Manhattan",
      utc: "-05:00",
   },
   {
      label: "America/Toronto (GMT-05:00)",
      tzCode: "America/Toronto",
      name: "(GMT-05:00) Toronto, Montréal, Ottawa, Mississauga, Québec",
      utc: "-05:00",
   },
   {
      label: "America/Barbados (GMT-04:00)",
      tzCode: "America/Barbados",
      name: "(GMT-04:00) Bridgetown, Speightstown, Oistins, Bathsheba, Holetown",
      utc: "-04:00",
   },
   {
      label: "America/Halifax (GMT-04:00)",
      tzCode: "America/Halifax",
      name: "(GMT-04:00) Halifax, Dartmouth, Charlottetown, Lower Sackville, Truro",
      utc: "-04:00",
   },
   {
      label: "America/Puerto Rico (GMT-04:00)",
      tzCode: "America/Puerto_Rico",
      name: "(GMT-04:00) San Juan, Bayamón, Carolina, Ponce, Caguas",
      utc: "-04:00",
   },
   {
      label: "America/Santiago (GMT-04:00)",
      tzCode: "America/Santiago",
      name: "(GMT-04:00) Santiago, Puente Alto, Antofagasta, Viña del Mar, Valparaíso",
      utc: "-04:00",
   },
   {
      label: "America/Argentina/Buenos Aires (GMT-03:00)",
      tzCode: "America/Argentina/Buenos_Aires",
      name: "(GMT-03:00) Buenos Aires, La Plata, Mar del Plata, Morón, Bahía Blanca",
      utc: "-03:00",
   },
   {
      label: "America/Sao Paulo (GMT-03:00)",
      tzCode: "America/Sao_Paulo",
      name: "(GMT-03:00) São Paulo, Rio de Janeiro, Belo Horizonte, Brasília, Curitiba",
      utc: "-03:00",
   },
   {
      label: "Atlantic/South Georgia (GMT-02:00)",
      tzCode: "Atlantic/South_Georgia",
      name: "(GMT-02:00) Grytviken",
      utc: "-02:00",
   },
   {
      label: "Atlantic/Azores (GMT-01:00)",
      tzCode: "Atlantic/Azores",
      name: "(GMT-01:00) Ponta Delgada, Lagoa, Angra do Heroísmo, Rosto de Cão, Rabo de Peixe",
      utc: "-01:00",
   },
   {
      label: "Africa/Casablanca (GMT+00:00)",
      tzCode: "Africa/Casablanca",
      name: "(GMT+00:00) Casablanca, Rabat, Fès, Sale, Marrakesh",
      utc: "+00:00",
   },
   {
      label: "Atlantic/Reykjavik (GMT+00:00)",
      tzCode: "Atlantic/Reykjavik",
      name: "(GMT+00:00) Reykjavík, Kópavogur, Hafnarfjörður, Akureyri, Garðabær",
      utc: "+00:00",
   },
   {
      label: "Europe/Dublin (GMT+00:00)",
      tzCode: "Europe/Dublin",
      name: "(GMT+00:00) Dublin, Cork, Luimneach, Gaillimh, Tallaght",
      utc: "+00:00",
   },
   {
      label: "Europe/London (GMT+00:00)",
      tzCode: "Europe/London",
      name: "(GMT+00:00) London, Birmingham, Liverpool, Sheffield, Bristol",
      utc: "+00:00",
   },
   {
      label: "Africa/Lagos (GMT+01:00)",
      tzCode: "Africa/Lagos",
      name: "(GMT+01:00) Lagos, Kano, Ibadan, Kaduna, Port Harcourt",
      utc: "+01:00",
   },
   {
      label: "Europe/Amsterdam (GMT+01:00)",
      tzCode: "Europe/Amsterdam",
      name: "(GMT+01:00) Amsterdam, Rotterdam, The Hague, Utrecht, Eindhoven",
      utc: "+01:00",
   },
   {
      label: "Europe/Andorra (GMT+01:00)",
      tzCode: "Europe/Andorra",
      name: "(GMT+01:00) Andorra la Vella, les Escaldes, Encamp, Sant Julià de Lòria, la Massana",
      utc: "+01:00",
   },
   {
      label: "Europe/Belgrade (GMT+01:00)",
      tzCode: "Europe/Belgrade",
      name: "(GMT+01:00) Belgrade, Pristina, Niš, Novi Sad, Prizren",
      utc: "+01:00",
   },
   {
      label: "Europe/Berlin (GMT+01:00)",
      tzCode: "Europe/Berlin",
      name: "(GMT+01:00) Berlin, Hamburg, Munich, Köln, Frankfurt am Main",
      utc: "+01:00",
   },
   {
      label: "Europe/Bratislava (GMT+01:00)",
      tzCode: "Europe/Bratislava",
      name: "(GMT+01:00) Bratislava, Košice, Prešov, Nitra, Žilina",
      utc: "+01:00",
   },
   {
      label: "Europe/Brussels (GMT+01:00)",
      tzCode: "Europe/Brussels",
      name: "(GMT+01:00) Brussels, Antwerpen, Gent, Charleroi, Liège",
      utc: "+01:00",
   },
   {
      label: "Europe/Budapest (GMT+01:00)",
      tzCode: "Europe/Budapest",
      name: "(GMT+01:00) Budapest, Debrecen, Miskolc, Szeged, Pécs",
      utc: "+01:00",
   },
   {
      label: "Europe/Copenhagen (GMT+01:00)",
      tzCode: "Europe/Copenhagen",
      name: "(GMT+01:00) Copenhagen, Århus, Odense, Aalborg, Frederiksberg",
      utc: "+01:00",
   },
   {
      label: "Europe/Luxembourg (GMT+01:00)",
      tzCode: "Europe/Luxembourg",
      name: "(GMT+01:00) Luxembourg, Esch-sur-Alzette, Dudelange, Schifflange, Bettembourg",
      utc: "+01:00",
   },
   {
      label: "Europe/Madrid (GMT+01:00)",
      tzCode: "Europe/Madrid",
      name: "(GMT+01:00) Madrid, Barcelona, Valencia, Sevilla, Zaragoza",
      utc: "+01:00",
   },
   {
      label: "Europe/Oslo (GMT+01:00)",
      tzCode: "Europe/Oslo",
      name: "(GMT+01:00) Oslo, Bergen, Trondheim, Stavanger, Drammen",
      utc: "+01:00",
   },
   {
      label: "Europe/Paris (GMT+01:00)",
      tzCode: "Europe/Paris",
      name: "(GMT+01:00) Paris, Marseille, Lyon, Toulouse, Nice",
      utc: "+01:00",
   },
   {
      label: "Europe/Prague (GMT+01:00)",
      tzCode: "Europe/Prague",
      name: "(GMT+01:00) Prague, Brno, Ostrava, Pilsen, Olomouc",
      utc: "+01:00",
   },
   {
      label: "Europe/Rome (GMT+01:00)",
      tzCode: "Europe/Rome",
      name: "(GMT+01:00) Rome, Milan, Naples, Turin, Palermo",
      utc: "+01:00",
   },
   {
      label: "Europe/Vienna (GMT+01:00)",
      tzCode: "Europe/Vienna",
      name: "(GMT+01:00) Vienna, Graz, Linz, Favoriten, Donaustadt",
      utc: "+01:00",
   },
   {
      label: "Europe/Warsaw (GMT+01:00)",
      tzCode: "Europe/Warsaw",
      name: "(GMT+01:00) Warsaw, Łódź, Kraków, Wrocław, Poznań",
      utc: "+01:00",
   },
   {
      label: "Europe/Zagreb (GMT+01:00)",
      tzCode: "Europe/Zagreb",
      name: "(GMT+01:00) Zagreb, Split, Rijeka, Osijek, Zadar",
      utc: "+01:00",
   },
   {
      label: "Europe/Zurich (GMT+01:00)",
      tzCode: "Europe/Zurich",
      name: "(GMT+01:00) Zürich, Genève, Basel, Lausanne, Bern",
      utc: "+01:00",
   },
   {
      label: "Africa/Cairo (GMT+02:00)",
      tzCode: "Africa/Cairo",
      name: "(GMT+02:00) Cairo, Alexandria, Giza, Port Said, Suez",
      utc: "+02:00",
   },
   {
      label: "Africa/Johannesburg (GMT+02:00)",
      tzCode: "Africa/Johannesburg",
      name: "(GMT+02:00) Cape Town, Durban, Johannesburg, Soweto, Pretoria",
      utc: "+02:00",
   },
   {
      label: "Africa/Khartoum (GMT+02:00)",
      tzCode: "Africa/Khartoum",
      name: "(GMT+02:00) Khartoum, Omdurman, Nyala, Port Sudan, Kassala",
      utc: "+02:00",
   },
   {
      label: "Africa/Tripoli (GMT+02:00)",
      tzCode: "Africa/Tripoli",
      name: "(GMT+02:00) Tripoli, Benghazi, Mişrātah, Tarhuna, Al Khums",
      utc: "+02:00",
   },
   {
      label: "Asia/Beirut (GMT+02:00)",
      tzCode: "Asia/Beirut",
      name: "(GMT+02:00) Beirut, Ra’s Bayrūt, Tripoli, Sidon, Tyre",
      utc: "+02:00",
   },
   {
      label: "Asia/Damascus (GMT+02:00)",
      tzCode: "Asia/Damascus",
      name: "(GMT+02:00) Aleppo, Damascus, Homs, Ḩamāh, Latakia",
      utc: "+02:00",
   },
   {
      label: "Asia/Jerusalem (GMT+02:00)",
      tzCode: "Asia/Jerusalem",
      name: "(GMT+02:00) Jerusalem, Tel Aviv, West Jerusalem, Haifa, Ashdod",
      utc: "+02:00",
   },
   {
      label: "Europe/Athens (GMT+02:00)",
      tzCode: "Europe/Athens",
      name: "(GMT+02:00) Athens, Thessaloníki, Pátra, Piraeus, Lárisa",
      utc: "+02:00",
   },
   {
      label: "Europe/Bucharest (GMT+02:00)",
      tzCode: "Europe/Bucharest",
      name: "(GMT+02:00) Bucharest, Sector 3, Sector 6, Sector 2, Iaşi",
      utc: "+02:00",
   },
   {
      label: "Europe/Helsinki (GMT+02:00)",
      tzCode: "Europe/Helsinki",
      name: "(GMT+02:00) Helsinki, Espoo, Tampere, Vantaa, Turku",
      utc: "+02:00",
   },
   {
      label: "Europe/Kiev (GMT+02:00)",
      tzCode: "Europe/Kiev",
      name: "(GMT+02:00) Kyiv, Kharkiv, Donetsk, Odessa, Dnipro",
      utc: "+02:00",
   },
   {
      label: "Europe/Tallinn (GMT+02:00)",
      tzCode: "Europe/Tallinn",
      name: "(GMT+02:00) Tallinn, Tartu, Narva, Kohtla-Järve, Pärnu",
      utc: "+02:00",
   },
   {
      label: "Africa/Addis Ababa (GMT+03:00)",
      tzCode: "Africa/Addis_Ababa",
      name: "(GMT+03:00) Addis Ababa, Dire Dawa, Mek'ele, Nazrēt, Bahir Dar",
      utc: "+03:00",
   },
   {
      label: "Asia/Bahrain (GMT+03:00)",
      tzCode: "Asia/Bahrain",
      name: "(GMT+03:00) Manama, Al Muharraq, Ar Rifā‘, Dār Kulayb, Madīnat Ḩamad",
      utc: "+03:00",
   },
   {
      label: "Asia/Kuwait (GMT+03:00)",
      tzCode: "Asia/Kuwait",
      name: "(GMT+03:00) Al Aḩmadī, Ḩawallī, As Sālimīyah, Şabāḩ as Sālim, Al Farwānīyah",
      utc: "+03:00",
   },
   {
      label: "Asia/Qatar (GMT+03:00)",
      tzCode: "Asia/Qatar",
      name: "(GMT+03:00) Doha, Ar Rayyān, Umm Şalāl Muḩammad, Al Wakrah, Al Khawr",
      utc: "+03:00",
   },
   {
      label: "Asia/Riyadh (GMT+03:00)",
      tzCode: "Asia/Riyadh",
      name: "(GMT+03:00) Riyadh, Jeddah, Mecca, Medina, Sulţānah",
      utc: "+03:00",
   },
   {
      label: "Europe/Istanbul (GMT+03:00)",
      tzCode: "Europe/Istanbul",
      name: "(GMT+03:00) Istanbul, Ankara, İzmir, Bursa, Adana",
      utc: "+03:00",
   },
   {
      label: "Europe/Moscow (GMT+03:00)",
      tzCode: "Europe/Moscow",
      name: "(GMT+03:00) Moscow, Saint Petersburg, Nizhniy Novgorod, Kazan, Rostov-na-Donu",
      utc: "+03:00",
   },
   {
      label: "Asia/Tehran (GMT+03:30)",
      tzCode: "Asia/Tehran",
      name: "(GMT+03:30) Tehran, Mashhad, Isfahan, Karaj, Tabriz",
      utc: "+03:30",
   },
   {
      label: "Asia/Dubai (GMT+04:00)",
      tzCode: "Asia/Dubai",
      name: "(GMT+04:00) Dubai, Sharjah, Abu Dhabi, Ajman City, Ras Al Khaimah City",
      utc: "+04:00",
   },
   {
      label: "Asia/Kabul (GMT+04:30)",
      tzCode: "Asia/Kabul",
      name: "(GMT+04:30) Kabul, Kandahār, Mazār-e Sharīf, Herāt, Jalālābād",
      utc: "+04:30",
   },
   {
      label: "Asia/Karachi (GMT+05:00)",
      tzCode: "Asia/Karachi",
      name: "(GMT+05:00) Karachi, Lahore, Faisalabad, Rawalpindi, Multan",
      utc: "+05:00",
   },
   {
      label: "Asia/Kolkata (GMT+05:30)",
      tzCode: "Asia/Kolkata",
      name: "(GMT+05:30) Mumbai, Delhi, Bengaluru, Kolkata, Chennai",
      utc: "+05:30",
   },
   {
      label: "Asia/Kathmandu (GMT+05:45)",
      tzCode: "Asia/Kathmandu",
      name: "(GMT+05:45) Kathmandu, Pokhara, Pātan, Biratnagar, Birgañj",
      utc: "+05:45",
   },
   {
      label: "Antarctica/Vostok (GMT+06:00)",
      tzCode: "Antarctica/Vostok",
      name: "(GMT+06:00) Vostok",
      utc: "+06:00",
   },
   {
      label: "Asia/Bangkok (GMT+07:00)",
      tzCode: "Asia/Bangkok",
      name: "(GMT+07:00) Bangkok, Hanoi, Haiphong, Samut Prakan, Mueang Nonthaburi",
      utc: "+07:00",
   },
   {
      label: "Asia/Jakarta (GMT+07:00)",
      tzCode: "Asia/Jakarta",
      name: "(GMT+07:00) Jakarta, Surabaya, Medan, Bandung, Bekasi",
      utc: "+07:00",
   },
   {
      label: "Asia/Hong Kong (GMT+08:00)",
      tzCode: "Asia/Hong_Kong",
      name: "(GMT+08:00) Hong Kong, Kowloon, Tsuen Wan, Yuen Long Kau Hui, Tung Chung",
      utc: "+08:00",
   },
   {
      label: "Asia/Kuala Lumpur (GMT+08:00)",
      tzCode: "Asia/Kuala_Lumpur",
      name: "(GMT+08:00) Kota Bharu, Kuala Lumpur, Klang, Kampung Baru Subang, Johor Bahru",
      utc: "+08:00",
   },
   {
      label: "Asia/Shanghai (GMT+08:00)",
      tzCode: "Asia/Shanghai",
      name: "(GMT+08:00) Shanghai, Beijing, Tianjin, Guangzhou, Shenzhen",
      utc: "+08:00",
   },
   {
      label: "Asia/Singapore (GMT+08:00)",
      tzCode: "Asia/Singapore",
      name: "(GMT+08:00) Singapore, Woodlands",
      utc: "+08:00",
   },
   {
      label: "Asia/Taipei (GMT+08:00)",
      tzCode: "Asia/Taipei",
      name: "(GMT+08:00) Taipei, Kaohsiung, Taichung, Tainan, Banqiao",
      utc: "+08:00",
   },
   {
      label: "Australia/Perth (GMT+08:00)",
      tzCode: "Australia/Perth",
      name: "(GMT+08:00) Perth, Rockingham, Mandurah, Bunbury, Albany",
      utc: "+08:00",
   },
   {
      label: "Australia/Eucla (GMT+08:45)",
      tzCode: "Australia/Eucla",
      name: "(GMT+08:45) Eucla",
      utc: "+08:45",
   },
   {
      label: "Asia/Seoul (GMT+09:00)",
      tzCode: "Asia/Seoul",
      name: "(GMT+09:00) Seoul, Busan, Incheon, Daegu, Daejeon",
      utc: "+09:00",
   },
   {
      label: "Asia/Tokyo (GMT+09:00)",
      tzCode: "Asia/Tokyo",
      name: "(GMT+09:00) Tokyo, Yokohama, Osaka, Nagoya, Sapporo",
      utc: "+09:00",
   },
   {
      label: "Australia/Adelaide (GMT+09:30)",
      tzCode: "Australia/Adelaide",
      name: "(GMT+09:30) Adelaide, Adelaide Hills, Mount Gambier, Morphett Vale, Gawler",
      utc: "+09:30",
   },
   {
      label: "Australia/Brisbane (GMT+10:00)",
      tzCode: "Australia/Brisbane",
      name: "(GMT+10:00) Brisbane, Gold Coast, Logan City, Townsville, Cairns",
      utc: "+10:00",
   },
   {
      label: "Australia/Melbourne (GMT+10:00)",
      tzCode: "Australia/Melbourne",
      name: "(GMT+10:00) Melbourne, Geelong, Bendigo, Ballarat, Melbourne City Centre",
      utc: "+10:00",
   },
   {
      label: "Australia/Sydney (GMT+10:00)",
      tzCode: "Australia/Sydney",
      name: "(GMT+10:00) Sydney, Canberra, Newcastle, Wollongong, Maitland",
      utc: "+10:00",
   },
   {
      label: "Pacific/Guadalcanal (GMT+11:00)",
      tzCode: "Pacific/Guadalcanal",
      name: "(GMT+11:00) Honiara, Malango, Auki, Gizo, Buala",
      utc: "+11:00",
   },
   {
      label: "Antarctica/McMurdo (GMT+12:00)",
      tzCode: "Antarctica/McMurdo",
      name: "(GMT+12:00) McMurdo",
      utc: "+12:00",
   },
   {
      label: "Pacific/Auckland (GMT+12:00)",
      tzCode: "Pacific/Auckland",
      name: "(GMT+12:00) Auckland, Wellington, Christchurch, Manukau City, North Shore",
      utc: "+12:00",
   },
   {
      label: "Pacific/Fiji (GMT+12:00)",
      tzCode: "Pacific/Fiji",
      name: "(GMT+12:00) Suva, Lautoka, Nadi, Labasa, Ba",
      utc: "+12:00",
   },
];

export default timezones;
