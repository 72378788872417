import React, { useState, useEffect } from "react";
import { Textfit } from "react-textfit";
import timezones from "./Timezones";

import {
   TextField,
   Autocomplete,
   Select,
   MenuItem,
   InputLabel,
   Button,
   Grid,
   Box,
   Stack,
   Modal,
} from "@mui/material";
import { Delete, Close } from "@mui/icons-material";

import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

const modalStyle = {
   position: "absolute",
   top: "10vw",
   left: "50%",
   transform: "translate(-50%, 0%)",
   width: "90%",
   maxWidth: 600,
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   px: 5,
   py: 10,
   boxSizing: "border-box",
   overflowY: "auto",
   pointerEvents: "auto",
};

function findTZIndex(tzCode) {
   if (!tzCode) {
      return 0;
   } else {
      return timezones.findIndex((element) => element.tzCode === tzCode);
   }
}

function FormatTimeString({ theme, timeString }) {
   if (theme === "night") {
      timeString = timeString.split("").map((char) => {
         if (char === ":") {
            return (
               <Box
                  sx={{
                     width: ".2em",
                     textAlign: "center",
                     transform: "translateY(-15%)",
                  }}
               >
                  {char}
               </Box>
            );
         } else {
            return <Box sx={{ width: ".5em", textAlign: "right" }}>{char}</Box>;
         }
      });

      return (
         <Stack direction='row' spacing={0}>
            {timeString}
         </Stack>
      );
   } else {
      return timeString;
   }
}

function ClockComponent(props) {
   const [value, setValue] = React.useState();
   const [inputValue, setInputValue] = React.useState("");

   const [openTimezoneMenu, setOpenTimezoneMenu] = useState(false);
   const handleTimezoneMenuOpen = () => setOpenTimezoneMenu(true);
   const handleTimezoneMenuClose = () => setOpenTimezoneMenu(false);

   return (
      <>
         <Stack
            sx={{
               width: 1920,
               height: 600,
               textAlign: "center",
            }}
            alignItems='center'
            justifyContent='center'
         >
            <h1>
               {props.timezone != 0 && (
                  <FormatTimeString
                     theme={props.theme}
                     timeString={props.currentTime.toLocaleTimeString(
                        props.locale,
                        {
                           timeZone: timezones[props.timezone].tzCode,
                        }
                     )}
                  />
               )}
               {props.timezone == 0 && (
                  <FormatTimeString
                     theme={props.theme}
                     timeString={props.currentTime.toLocaleTimeString(
                        props.locale
                     )}
                  />
               )}
            </h1>

            <h2>
               {props.timezone != 0
                  ? timezones[props.timezone].tzCode.replace("_", " ")
                  : "Local Time"}
            </h2>

            {props.edit && !props.lock && (
               <Stack
                  direction='row'
                  spacing={2}
                  alignItems='center'
                  justifyContent='center'
                  sx={{
                     transform: "scale(" + 0.9 / props.parent.scale + ")",
                     transformOrigin: "top center",
                  }}
               >
                  <Button
                     onClick={handleTimezoneMenuOpen}
                     size='small'
                     variant='contained'
                     className='url primary'
                  >
                     Edit
                  </Button>
                  {props.clockId != 0 && (
                     <Button
                        size='small'
                        variant='contained'
                        className='url primary'
                        sx={{ backgroundColor: "#f00 !important" }}
                        onClick={() => props.deleteClock(props.clockId)}
                     >
                        <Delete />
                     </Button>
                  )}
               </Stack>
            )}
         </Stack>
         <Modal
            open={openTimezoneMenu}
            onClose={handleTimezoneMenuClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
         >
            <Box sx={modalStyle}>
               <motion.div key={"clockDiv" + props.clockId}>
                  <Grid container justifyContent='center' alignItems='center'>
                     <Grid item xs={12}>
                        <InputLabel id='timezoneSelect'>
                           Select Clock {props.clockId + 1} Timezone
                        </InputLabel>

                        <Select
                           labelId='timezoneSelect'
                           size='small'
                           value={timezones[props.timezone].tzCode}
                           onChange={(event) => {
                              const newValue = event.target.value;

                              if (newValue) {
                                 props.timezoneChange(
                                    findTZIndex(newValue),
                                    props.clockId
                                 );
                              } else {
                                 props.timezoneChange(0, props.clockId);
                              }
                           }}
                           sx={{ width: "100%", mt: 2, mb: 1, mx: "auto" }}
                        >
                           {timezones.map((timezone, i) => (
                              <MenuItem value={timezone.tzCode}>
                                 {timezones[i].label}
                              </MenuItem>
                           ))}
                        </Select>
                     </Grid>
                  </Grid>
                  <Button
                     size='small'
                     variant='outlined'
                     sx={{ position: "absolute", top: 10, right: 10 }}
                     onClick={handleTimezoneMenuClose}
                     startIcon={<Close />}
                  >
                     Close
                  </Button>
               </motion.div>
            </Box>
         </Modal>
      </>
   );
}

export default ClockComponent;
