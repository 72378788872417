import React, { useState, useEffect, useRef, useMemo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AnalogClockComponent from "./AnalogClockComponent";
import ClockComponent from "./ClockComponent";
import useWindowDimensions from "./useWindowDimensions";
import logo from "./pdlogo.png";

import ScaleToWidth from "./ScaleToWidth.js";

import axios from "axios";

import {
   Button,
   Box,
   Grid,
   Stack,
   Modal,
   Typography,
   Drawer,
   useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { ContentCopy, Lock, Close, Menu } from "@mui/icons-material";

import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import _ from "lodash";

const ESCAPE_KEYS = ["27", "Escape"];

const useEventListener = (eventName, handler, element = window) => {
   const savedHandler = useRef();

   useEffect(() => {
      savedHandler.current = handler;
   }, [handler]);

   useEffect(() => {
      const eventListener = (event) => savedHandler.current(event);
      element.addEventListener(eventName, eventListener);
      return () => {
         element.removeEventListener(eventName, eventListener);
      };
   }, [eventName, element]);
};

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   maxWidth: 600,
   width: "100%",
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   p: 5,
   boxSizing: "border-box",
   overflowY: "auto",
   pointerEvents: "auto",
};

function openFullscreen() {
   if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
   } else if (document.body.webkitRequestFullscreen) {
      /* Safari */
      document.body.webkitRequestFullscreen();
   } else if (document.body.msRequestFullscreen) {
      /* IE11 */
      document.body.msRequestFullscreen();
   }
}

function closeFullscreen() {
   if (document.fullscreenElement !== null) {
      if (document.exitFullscreen) {
         document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
         /* Safari */
         document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
         /* IE11 */
         document.msExitFullscreen();
      }
   }
}

function hexToRgb(hex) {
   var bigint = parseInt(hex.replace("#", ""), 16);
   var r = (bigint >> 16) & 255;
   var g = (bigint >> 8) & 255;
   var b = bigint & 255;

   return (
      "linear-gradient(180deg, rgba(" +
      r +
      "," +
      g +
      "," +
      b +
      ", .8) 70%, rgba(" +
      r +
      "," +
      g +
      "," +
      b +
      ", 0) 100%)"
   );
}

function App() {
   const muiTheme = useTheme();
   const matches = useMediaQuery(muiTheme.breakpoints.up("md"));

   const [currentTime, setCurrentTime] = useState();
   const [clocks, setClocks] = useState([0]);
   const [lock, setLock] = useState(false);
   const [edit, setEdit] = useState(false);
   const [theme, setTheme] = useState("light");
   const [locale, setLocale] = useState("en-US");
   const [type, setType] = useState("digital");
   const [fullscreen, setFullscreen] = useState(false);
   const [drawerOpen, setDrawerOpen] = useState(false);
   const [url, setUrl] = useState();
   const [urlLocked, setUrlLocked] = useState();
   const [timeOffset, setTimeOffset] = useState(0);

   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const { height, width } = useWindowDimensions();

   const themeColors = {
      light: { text: "#333", bg: "#ffffff" },
      dark: { text: "#999", bg: "#333333" },
      night: { text: "#f00", bg: "#222222" },
   };

   function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
         width,
         height,
      };
   }

   const handler = ({ key }) => {
      if (ESCAPE_KEYS.includes(String(key))) {
         setEdit(!edit);
      }
   };

   useEventListener("keydown", handler);

   const onMouseMove = (e) => {
      if (!lock && !edit) {
         setEdit(true);
      }
      debounceHide(e);
   };

   const handleThemeChange = (event, next) => {
      if (next) {
         setTheme(next);
      }
   };

   const handleLocaleChange = (event, next) => {
      if (next) {
         setLocale(next);
      }
   };

   const handleTypeChange = (event, next) => {
      if (next) {
         setType(next);
      }
   };

   const debounceHide = useMemo(() => {
      const debounce = _.debounce((e) => setEdit(false), 4000);
      return (e) => {
         e.persist();
         return debounce(e);
      };
   }, []);

   const toggleDrawer = () => {
      setDrawerOpen(!drawerOpen);
   };

   useEffect(() => {
      setUrl(
         window.location.origin +
            "/false/" +
            theme +
            "/" +
            locale +
            "/" +
            type +
            "/" +
            clocks.join("/")
      );

      setUrlLocked(
         window.location.origin +
            "/true/" +
            theme +
            "/" +
            locale +
            "/" +
            type +
            "/" +
            clocks.join("/")
      );
   }, [theme, locale, type, clocks]);

   useEffect(() => {
      let currentTime = JSON.stringify(Date.now());

      axios
         .get("https://justabigclock.com/servertime.php")
         .then(function (response) {
            {
               /* console.log("Client Time: " + currentTime);
            console.log("Server Time: " + response.data);
            console.log(
               "Time Offset: " +
                  (parseInt(response.data) - parseInt(currentTime))
            ); 
            
            console.log(
               "Response Time: " +
                  (parseInt(currentTime) - parseInt(responseTime))
            );
            */
            }

            let responseTime = JSON.stringify(Date.now());
            setTimeOffset(
               parseInt(response.data) -
                  parseInt(currentTime) +
                  (parseInt(currentTime) - parseInt(responseTime))
            );

            setTimeout(function () {
               const timerId = setInterval(() => {
                  setCurrentTime(
                     new Date(
                        new Date().getTime() +
                           (parseInt(response.data) - parseInt(currentTime))
                     )
                  );
               }, 100);
               return function cleanup() {
                  clearInterval(timerId);
               };
            }, 100 - currentTime.substr(-2));
         })
         .catch(function (error) {
            console.log(error);
         });

      if (window.location.pathname != "/") {
         setLock(JSON.parse(window.location.pathname.split("/").slice(1)[0]));
         setTheme(window.location.pathname.split("/").slice(1)[1]);
         setLocale(window.location.pathname.split("/").slice(1)[2]);
         setType(window.location.pathname.split("/").slice(1)[3]);
         setClocks(window.location.pathname.split("/").slice(5));
      }
   }, []);

   const addClock = () => {
      if (clocks.length < 4) {
         setClocks([...clocks, 0]);
      } else {
         alert("Only 4 clocks allowed!");
      }
   };

   const deleteClock = (id) => {
      let clockValues = [...clocks];
      clockValues.splice(id, 1);
      setClocks(clockValues);
   };

   const debounceFire = (e) => {
      if (e) {
         debounceHide(e);
      }
   };

   const changeTimezone = (timezone, id) => {
      let clockValues = [...clocks];

      clockValues[id] = parseInt(timezone);
      setClocks(clockValues);
   };

   return (
      <div
         className={"container theme-" + theme + " edit-" + edit}
         onMouseMove={onMouseMove}
      >
         <AnimatePresence>
            {edit && !lock && !matches && (
               <motion.div
                  key='editBurger'
                  className='editBurger'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
               >
                  <Button
                     className='primary'
                     onClick={toggleDrawer}
                     variant='contained'
                  >
                     <Menu />
                  </Button>
                  <Drawer
                     style={{ opacity: 0.92 }}
                     open={drawerOpen}
                     onClose={toggleDrawer}
                  >
                     <Box
                        sx={{
                           boxSizing: "border-box",
                           px: 2,
                           py: 4,
                           width: "100%",
                           maxWidth: 400,
                           height: "100%",
                           overflowY: "auto",
                           pointerEvents: "auto",
                        }}
                        className={"theme-" + theme}
                     >
                        <Grid container spacing={2}>
                           <Grid item xs={12} sx={{ textAlign: "right" }}>
                              <Button color='error' onClick={toggleDrawer}>
                                 <Close />
                              </Button>
                           </Grid>
                           <Grid item xs={12}>
                              <Button
                                 fullWidth
                                 size='small'
                                 variant='contained'
                                 className='primary'
                                 onClick={addClock}
                              >
                                 Add Clock
                              </Button>
                           </Grid>
                           <Grid item xs={12}>
                              <ToggleButtonGroup
                                 size='small'
                                 className='toggle'
                                 sx={{
                                    backgroundColor: themeColors[theme].bg,
                                 }}
                                 color='primary'
                                 value={theme}
                                 exclusive
                                 onChange={handleThemeChange}
                              >
                                 <ToggleButton value='light'>
                                    <span
                                       style={{
                                          color: themeColors[theme].text,
                                       }}
                                    >
                                       Light
                                    </span>
                                 </ToggleButton>
                                 <ToggleButton value='dark'>
                                    <span
                                       style={{
                                          color: themeColors[theme].text,
                                       }}
                                    >
                                       Dark
                                    </span>
                                 </ToggleButton>
                                 <ToggleButton value='night'>
                                    <span
                                       style={{
                                          color: themeColors[theme].text,
                                       }}
                                    >
                                       Night
                                    </span>
                                 </ToggleButton>
                              </ToggleButtonGroup>
                           </Grid>
                           <Grid item xs={12}>
                              <ToggleButtonGroup
                                 size='small'
                                 className='toggle'
                                 sx={{
                                    backgroundColor: themeColors[theme].bg,
                                 }}
                                 color='primary'
                                 value={locale}
                                 exclusive
                                 onChange={handleLocaleChange}
                              >
                                 <ToggleButton value='en-GB'>
                                    <span
                                       style={{
                                          color: themeColors[theme].text,
                                       }}
                                    >
                                       24hr
                                    </span>
                                 </ToggleButton>
                                 <ToggleButton value='en-US'>
                                    <span
                                       style={{
                                          color: themeColors[theme].text,
                                       }}
                                    >
                                       12hr
                                    </span>
                                 </ToggleButton>
                              </ToggleButtonGroup>
                           </Grid>
                           <Grid item xs={12}>
                              <ToggleButtonGroup
                                 size='small'
                                 className='toggle'
                                 sx={{
                                    backgroundColor: themeColors[theme].bg,
                                 }}
                                 color='primary'
                                 value={type}
                                 exclusive
                                 onChange={handleTypeChange}
                              >
                                 <ToggleButton value='digital'>
                                    <span
                                       style={{
                                          color: themeColors[theme].text,
                                       }}
                                    >
                                       Digital
                                    </span>
                                 </ToggleButton>
                                 <ToggleButton value='analog'>
                                    <span
                                       style={{
                                          color: themeColors[theme].text,
                                       }}
                                    >
                                       Analog
                                    </span>
                                 </ToggleButton>
                              </ToggleButtonGroup>
                           </Grid>
                           <Grid item xs={12}>
                              <CopyToClipboard text={url}>
                                 <Button
                                    fullWidth
                                    size='small'
                                    variant='contained'
                                    className='url primary'
                                 >
                                    <ContentCopy sx={{ mr: 1 }} />
                                    COPY URL
                                 </Button>
                              </CopyToClipboard>
                           </Grid>
                           <Grid item xs={12}>
                              <CopyToClipboard text={urlLocked}>
                                 <Button
                                    fullWidth
                                    size='small'
                                    variant='contained'
                                    className='url primary'
                                 >
                                    <Lock sx={{ mr: 1 }} />
                                    COPY LOCKED URL
                                 </Button>
                              </CopyToClipboard>
                           </Grid>
                        </Grid>

                        <center>
                           <a
                              href='https://punch-drunk.com'
                              target='_new'
                              style={{
                                 display: "block",
                                 margin: "2em 0 1em 0",
                              }}
                           >
                              <img
                                 src={logo}
                                 style={{
                                    width: 150,
                                 }}
                              />
                           </a>

                           <Button
                              variant='outlined'
                              color='error'
                              sx={{
                                 backgroundColor: themeColors[theme].bg,
                              }}
                              onClick={() => setOpen(true)}
                           >
                              Just a Big Clock
                           </Button>

                           <b
                              style={{
                                 display: "block",
                                 marginTop: "1em",
                                 color: "#666",
                                 fontSize: ".4em",
                                 textAlign: "center",
                              }}
                              className='timeOffset'
                           >
                              Time Offset: {timeOffset}ms
                           </b>
                        </center>
                     </Box>
                  </Drawer>
               </motion.div>
            )}
         </AnimatePresence>

         <AnimatePresence>
            {edit && !lock && matches && (
               <motion.div
                  key='editBar'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
               >
                  <a href='https://punch-drunk.com' target='_new'>
                     <img
                        src={logo}
                        style={{
                           position: "absolute",
                           width: 150,
                           bottom: 40,
                           right: 40,
                           zIndex: 100,
                        }}
                     />
                  </a>

                  <Button
                     variant='outlined'
                     color='error'
                     sx={{
                        position: "absolute",
                        bottom: 40,
                        left: 40,
                        zIndex: 100,
                        backgroundColor: themeColors[theme].bg,
                     }}
                     onClick={() => setOpen(true)}
                  >
                     Just a Big Clock
                  </Button>

                  <b
                     style={{
                        position: "absolute",
                        bottom: 40,
                        left: "50%",
                        transform: "translateX(-50%)",
                        zIndex: 100,
                        color: "#ccc",
                        fontSize: ".4em",
                     }}
                     className='timeOffset'
                  >
                     Time Offset: {timeOffset}ms
                  </b>

                  <Grid
                     container
                     spacing={2}
                     justifyContent='center'
                     alignItems='center'
                     sx={{
                        px: 1,
                        py: 2,
                        position: "fixed",
                        zIndex: 100,
                        background: hexToRgb(themeColors[theme].bg),
                     }}
                  >
                     <Grid item>
                        <Button
                           fullWidth
                           size='small'
                           variant='contained'
                           className='primary'
                           onClick={addClock}
                        >
                           Add Clock
                        </Button>
                     </Grid>
                     <Grid item>
                        <ToggleButtonGroup
                           size='small'
                           className='toggle'
                           sx={{
                              backgroundColor: themeColors[theme].bg,
                           }}
                           color='primary'
                           value={theme}
                           exclusive
                           onChange={handleThemeChange}
                        >
                           <ToggleButton value='light'>
                              <span
                                 style={{
                                    color: themeColors[theme].text,
                                 }}
                              >
                                 Light
                              </span>
                           </ToggleButton>
                           <ToggleButton value='dark'>
                              <span
                                 style={{
                                    color: themeColors[theme].text,
                                 }}
                              >
                                 Dark
                              </span>
                           </ToggleButton>
                           <ToggleButton value='night'>
                              <span
                                 style={{
                                    color: themeColors[theme].text,
                                 }}
                              >
                                 Night
                              </span>
                           </ToggleButton>
                        </ToggleButtonGroup>
                     </Grid>
                     <Grid item>
                        <ToggleButtonGroup
                           size='small'
                           className='toggle'
                           sx={{
                              backgroundColor: themeColors[theme].bg,
                           }}
                           color='primary'
                           value={locale}
                           exclusive
                           onChange={handleLocaleChange}
                        >
                           <ToggleButton value='en-GB'>
                              <span
                                 style={{
                                    color: themeColors[theme].text,
                                 }}
                              >
                                 24hr
                              </span>
                           </ToggleButton>
                           <ToggleButton value='en-US'>
                              <span
                                 style={{
                                    color: themeColors[theme].text,
                                 }}
                              >
                                 12hr
                              </span>
                           </ToggleButton>
                        </ToggleButtonGroup>
                     </Grid>
                     <Grid item>
                        <ToggleButtonGroup
                           size='small'
                           className='toggle'
                           sx={{
                              backgroundColor: themeColors[theme].bg,
                           }}
                           color='primary'
                           value={type}
                           exclusive
                           onChange={handleTypeChange}
                        >
                           <ToggleButton value='digital'>
                              <span
                                 style={{
                                    color: themeColors[theme].text,
                                 }}
                              >
                                 Digital
                              </span>
                           </ToggleButton>
                           <ToggleButton value='analog'>
                              <span
                                 style={{
                                    color: themeColors[theme].text,
                                 }}
                              >
                                 Analog
                              </span>
                           </ToggleButton>
                        </ToggleButtonGroup>
                     </Grid>
                     <Grid item>
                        {fullscreen === true && (
                           <Button
                              fullWidth
                              size='small'
                              sx={{ mr: 1 }}
                              variant='contained'
                              className='secondary'
                              onClick={() => {
                                 closeFullscreen();
                                 setFullscreen(false);
                              }}
                           >
                              EXIT FULLSCREEN
                           </Button>
                        )}

                        {fullscreen === false && (
                           <Button
                              fullWidth
                              size='small'
                              sx={{ mr: 1 }}
                              variant='contained'
                              className='secondary'
                              onClick={() => {
                                 openFullscreen();
                                 setFullscreen(true);
                              }}
                           >
                              FULLSCREEN
                           </Button>
                        )}
                     </Grid>
                     <Grid item>
                        <CopyToClipboard text={url}>
                           <Button
                              fullWidth
                              size='small'
                              variant='contained'
                              className='url primary'
                           >
                              <ContentCopy sx={{ mr: 1 }} />
                              COPY URL
                           </Button>
                        </CopyToClipboard>
                     </Grid>
                     <Grid item>
                        <CopyToClipboard text={urlLocked}>
                           <Button
                              fullWidth
                              size='small'
                              variant='contained'
                              className='url primary'
                           >
                              <Lock sx={{ mr: 1 }} />
                              COPY LOCKED URL
                           </Button>
                        </CopyToClipboard>
                     </Grid>
                  </Grid>
               </motion.div>
            )}
         </AnimatePresence>

         <Stack className='parent' justifyContent='center' alignItems='center'>
            {!currentTime && <>SYNCHRONIZING CLOCK TO SYSTEM TIME...</>}
            {currentTime && (
               <Grid
                  container
                  alignItems='center'
                  justifyContent='center'
                  rowSpacing={{ xs: 6, sm: 2 }}
               >
                  {clocks.map((clock, i) => (
                     <>
                        {type == "digital" && (
                           <Grid
                              item
                              xs={
                                 height / width < 1
                                    ? clocks.length > 1
                                       ? 6
                                       : 12
                                    : 12
                              }
                              sm={
                                 height / width < 1
                                    ? clocks.length > 1
                                       ? 6
                                       : 12
                                    : 12
                              }
                              md={
                                 i > 0
                                    ? clocks.length > 2
                                       ? 6
                                       : 12
                                    : clocks.length > 3
                                    ? 6
                                    : 12
                              }
                           >
                              <ScaleToWidth
                                 contentWidth={1920}
                                 contentHeight={600}
                              >
                                 {(parent) => (
                                    <ClockComponent
                                       key={"clock" + i}
                                       edit={edit}
                                       lock={lock}
                                       currentTime={currentTime}
                                       inputChange={debounceFire}
                                       timezoneChange={changeTimezone}
                                       clockId={i}
                                       timezone={clocks[i]}
                                       locale={locale}
                                       deleteClock={deleteClock}
                                       clockCount={clocks.length}
                                       matches={matches}
                                       parent={parent}
                                       theme={theme}
                                    />
                                 )}
                              </ScaleToWidth>
                           </Grid>
                        )}

                        {type == "analog" && (
                           <Grid
                              item
                              xs={height / width < 1 ? 3 : 6}
                              md={
                                 clocks.length === 1
                                    ? 12
                                    : clocks.length === 2
                                    ? 6
                                    : 3
                              }
                           >
                              <ScaleToWidth
                                 contentWidth={700}
                                 contentHeight={1080}
                              >
                                 {(parent) => (
                                    <AnalogClockComponent
                                       key={"analogclock" + i}
                                       edit={edit}
                                       lock={lock}
                                       currentTime={currentTime}
                                       inputChange={debounceFire}
                                       timezoneChange={changeTimezone}
                                       clockId={i}
                                       timezone={clocks[i]}
                                       locale={locale}
                                       deleteClock={deleteClock}
                                       clockCount={clocks.length}
                                       theme={theme}
                                       parent={parent}
                                    />
                                 )}
                              </ScaleToWidth>
                           </Grid>
                        )}
                     </>
                  ))}
               </Grid>
            )}
         </Stack>

         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
         >
            <Box sx={modalStyle}>
               <Button
                  sx={{ position: "absolute", top: 20, right: 20 }}
                  color='error'
                  onClick={() => setOpen(false)}
               >
                  <Close />
               </Button>
               <br />
               <Typography
                  id='modal-modal-title'
                  variant='h6'
                  component='h2'
                  align='center'
               >
                  About
               </Typography>
               <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                  <p>
                     Just A Big Clock was created by Seattle-based live video
                     broadcast & design company Punch Drunk Productions. We
                     built JABC after realizing there was no simple fullscreen
                     web-based clock with large digits and no screen
                     decorations.
                  </p>
                  <p>
                     JABC provides an instant clock(s) for your live broadcasts
                     and events. JABC can exist in a multiviewer, confidence
                     monitor, teleprompter, or any other display to help
                     producers, talent, and technicians stay in time.
                  </p>
               </Typography>
               <Typography align='center'>
                  <a href='https://punch-drunk.com' target='_new'>
                     <img src={logo} style={{ width: 100 }} />
                  </a>
               </Typography>
            </Box>
         </Modal>
      </div>
   );
}

export default App;
