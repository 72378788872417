import React, { useState, useEffect } from "react";
import { Textfit } from "react-textfit";
import timezones from "./Timezones";
import AnalogClock from "analog-clock-react";
import ClockFaceNight from "./clockface-night.png";
import ClockFaceDark from "./clockface-dark.png";
import ClockFaceLight from "./clockface-light.png";

import {
   TextField,
   Autocomplete,
   Select,
   MenuItem,
   InputLabel,
   Button,
   Grid,
   Box,
   Stack,
   Modal,
} from "@mui/material";

import { Delete, Close } from "@mui/icons-material";

import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

const modalStyle = {
   position: "absolute",
   top: "10vw",
   left: "50%",
   transform: "translate(-50%, 0%)",
   width: "90%",
   maxWidth: 600,
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   px: 5,
   py: 10,
   boxSizing: "border-box",
   overflowY: "auto",
   pointerEvents: "auto",
};

function findTZIndex(tzCode) {
   if (!tzCode) {
      return 0;
   } else {
      return timezones.findIndex((element) => element.tzCode === tzCode);
   }
}

function AnalogClockComponent(props) {
   const [openTimezoneMenu, setOpenTimezoneMenu] = useState(false);
   const handleTimezoneMenuOpen = () => setOpenTimezoneMenu(true);
   const handleTimezoneMenuClose = () => setOpenTimezoneMenu(false);

   const clockFaces = {
      light: { face: ClockFaceLight, color: "#000" },
      dark: { face: ClockFaceDark, color: "#fff" },
      night: { face: ClockFaceNight, color: "#f00" },
   };

   const [options, setOptions] = useState({
      useCustomTime: true,
      width: "600px",
      border: true,
      borderColor: "transparent",
      baseColor: "transparent",
      seconds: 0,
      minutes: 0,
      hours: 0,
   });

   useEffect(() => {
      setOptions({
         ...options,
         centerBorderColor: clockFaces[props.theme].color,
         handColors: {
            second: "#fd0c73",
            minute: clockFaces[props.theme].color,
            hour: clockFaces[props.theme].color,
         },
      });
   }, [props.theme]);

   useEffect(() => {
      {
         props.timezone != 0 &&
            setOptions((prevOptions) => {
               let timeArray = props.currentTime
                  .toLocaleTimeString(props.locale, {
                     timeZone: timezones[props.timezone].tzCode,
                  })
                  .split(":");

               return {
                  ...prevOptions,
                  hours: parseInt(timeArray[0]),
                  minutes: parseInt(timeArray[1]),
                  seconds: parseInt(timeArray[2]),
               };
            });
      }
      {
         props.timezone == 0 &&
            setOptions((prevOptions) => {
               let timeArray = props.currentTime
                  .toLocaleTimeString(props.locale)
                  .split(":");

               return {
                  ...prevOptions,
                  hours: parseInt(timeArray[0]),
                  minutes: parseInt(timeArray[1]),
                  seconds: parseInt(timeArray[2]),
               };
            });
      }
   }, [props.currentTime, props.timezone]);

   return (
      <Stack
         sx={{
            width: 700,
            height: 1080,
            textAlign: "center",
         }}
         alignItems='center'
         justifyContent='center'
      >
         <h1 className='analog'>
            {props.timezone != 0 &&
               props.currentTime.toLocaleTimeString("en-US", {
                  timeZone: timezones[props.timezone].tzCode,
               })}
            {props.timezone == 0 &&
               props.currentTime.toLocaleTimeString("en-US")}
         </h1>
         <div
            style={{
               display: "inline-block",
               backgroundImage: `url(${clockFaces[props.theme].face})`,
               backgroundSize: "contain",
               backgroundRepeat: "no-repeat",
               position: "relative",
            }}
         >
            <div>
               <AnalogClock {...options} />
            </div>
            <Stack>
               <h2 className='analog'>
                  {props.timezone != 0
                     ? timezones[props.timezone].tzCode.replace("_", " ")
                     : "Local Time"}
               </h2>
               {props.edit && !props.lock && (
                  <Stack
                     direction='row'
                     spacing={2}
                     alignItems='center'
                     justifyContent='center'
                     sx={{
                        pt: 2,
                        transform: "scale(" + 0.8 / props.parent.scale + ")",
                        transformOrigin: "top center",
                     }}
                  >
                     <Button
                        onClick={handleTimezoneMenuOpen}
                        size='small'
                        variant='contained'
                        className='url primary'
                     >
                        Edit
                     </Button>
                     {props.clockId != 0 && (
                        <Button
                           size='small'
                           variant='contained'
                           className='url primary'
                           sx={{ backgroundColor: "#f00 !important" }}
                           onClick={() => props.deleteClock(props.clockId)}
                        >
                           <Delete />
                        </Button>
                     )}
                  </Stack>
               )}
            </Stack>
         </div>

         <Modal
            open={openTimezoneMenu}
            onClose={handleTimezoneMenuClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
         >
            <Box sx={modalStyle}>
               <motion.div key={"clockDiv" + props.clockId}>
                  <Grid container justifyContent='center' alignItems='center'>
                     <Grid item xs={12}>
                        <InputLabel id='timezoneSelect'>
                           Select Clock {props.clockId + 1} Timezone
                        </InputLabel>

                        <Select
                           labelId='timezoneSelect'
                           size='small'
                           value={timezones[props.timezone].tzCode}
                           onChange={(event) => {
                              const newValue = event.target.value;

                              if (newValue) {
                                 props.timezoneChange(
                                    findTZIndex(newValue),
                                    props.clockId
                                 );
                              } else {
                                 props.timezoneChange(0, props.clockId);
                              }
                           }}
                           sx={{ width: "100%", mt: 2, mb: 1, mx: "auto" }}
                        >
                           {timezones.map((timezone, i) => (
                              <MenuItem value={timezone.tzCode}>
                                 {timezones[i].label}
                              </MenuItem>
                           ))}
                        </Select>
                     </Grid>
                  </Grid>
                  <Button
                     size='small'
                     variant='outlined'
                     sx={{ position: "absolute", top: 10, right: 10 }}
                     onClick={handleTimezoneMenuClose}
                     startIcon={<Close />}
                  >
                     Close
                  </Button>
               </motion.div>
            </Box>
         </Modal>
      </Stack>
   );
}

export default AnalogClockComponent;
